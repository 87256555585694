
import { colorBySectorId } from "~/helpers/rent-level-helpers.js";
import LoaderCopy from "../Loader/LoaderCopy.vue";
import TableCellBar from "./TableCellBar.vue";

export default {
  components: { LoaderCopy, TableCellBar },

  inheritAttrs: false,

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    simulationId: {
      type: String,
      required: true,
    },

    id: {
      type: String,
      default: undefined,
    },

    sectors: {
      type: Array,
      required: true,
    },

    name: {
      type: String,
      default: undefined,
    },

    sector: {
      type: String,
      default: undefined,
    },

    sectorId: {
      type: String,
      default: "RESIDENTIAL_FREE_RENT",
    },

    buildingNumber: {
      type: Number,
      default: undefined,
    },

    buildingNumbers: {
      type: Array,
      default: undefined,
    },

    buildingId: {
      type: String,
      default: undefined,
    },

    communeCode: {
      type: String,
      default: undefined,
    },

    usageCode: {
      type: String,
      default: undefined,
    },

    constructionYear: {
      type: String,
      default: undefined,
    },

    totalArea: {
      type: Number,
      default: undefined,
    },

    rentalPriceMin: {
      type: Number,
      default: undefined,
    },

    rentalPriceAverage: {
      type: Number,
      default: undefined,
    },

    rentalPriceMax: {
      type: Number,
      default: undefined,
    },

    rentalPriceYearly: {
      type: Number,
      default: undefined,
    },

    calculationAccuracy: {
      type: Number,
      default: undefined,
    },

    calculationAccuracyTooltip: {
      type: String,
      default() {
        return this.$t("VALUE_NOT_REGISTERED");
      },
    },
  },

  data: function () {
    return {
      debounce: null,
      sectorDebouce: null,
      constructionYearDebouce: null,
      debounceTimeoutMillis: 800,

      nameInternal: this.name,
      sectorIdInternal: this.sectorId,
      buildingNumberInternal: this.buildingNumber,
      constructionYearInternal: this.constructionYear,
      totalAreaInternal: this.totalArea,

      nameValid: true,
      totalAreaValid: true,
      constructionYearValid: true,
    };
  },

  computed: {
    getTypeFromSector() {
      switch (this.sectorId) {
        case "RESIDENTIAL_FREE_RENT":
          return this.$t("RENTAL_SECTOR_RESIDENTIAL");

        case "SHOP":
          return this.$t("RENTAL_SECTOR_COMMERCIAL");

        case "INDUSTRY":
          return this.$t("RENTAL_SECTOR_INDUSTRY");

        case "OFFICE":
          return this.$t("RENTAL_SECTOR_OFFICE");

        case "OTHER_BUSINESS":
          return this.$t("RENTAL_SECTOR_OTHER_BUSINESS");

        case "RESIDENTIAL_COST":
          return this.$t("RENTAL_SECTOR_RESIDENTIAL_COST");

        case "RESIDENTIAL_SMALL":
          return this.$t("RENTAL_SECTOR_RESIDENTIAL_SMALL_HOUSE_RENTAL");

        case "RESIDENTIAL_RENTAL":
          return this.$t("RENTAL_SECTOR_RESIDENTIAL_RENTAL_VALUE");
      }

      return this.$t("VALUE_NOT_REGISTERED");
    },

    getColorBySectorId() {
      return colorBySectorId(this.sectorId, false);
    },
  },

  watch: {
    nameInternal: function () {
      this.dataUpdatedDebounce();
    },

    buildingNumberInternal: function () {
      this.dataUpdatedDebounce();
    },

    totalAreaInternal: function () {
      if (this.totalAreaValid) {
        this.dataUpdatedDebounce();
      }
    },

    constructionYearInternal: function () {
      if (this.constructionYearValid) {
        this.constructionYearUpdatedDebouce();
      }
    },

    sectorIdInternal: function () {
      this.sectorUpdatedDebouce();
    },
  },

  methods: {
    getTypeFromSectorOld(sector) {
      switch (sector) {
        case "RESIDENTIAL_FREE_RENT":
          return this.$t("RENTAL_SECTOR_RESIDENTIAL");

        case "SHOP":
          return this.$t("RENTAL_SECTOR_COMMERCIAL");

        case "INDUSTRY":
          return this.$t("RENTAL_SECTOR_INDUSTRY");

        case "OFFICE":
          return this.$t("RENTAL_SECTOR_OFFICE");

        case "OTHER_BUSINESS":
          return this.$t("RENTAL_SECTOR_OTHER_BUSINESS");

        case "RESIDENTIAL_COST":
          return this.$t("RENTAL_SECTOR_RESIDENTIAL_COST");

        case "RESIDENTIAL_SMALL":
          return this.$t("RENTAL_SECTOR_RESIDENTIAL_SMALL_HOUSE_RENTAL");

        case "RESIDENTIAL_RENTAL":
          return this.$t("RENTAL_SECTOR_RESIDENTIAL_RENTAL_VALUE");
      }

      return this.$t("VALUE_NOT_REGISTERED");
    },

    getCurrentModel() {
      return {
        simulationId: this.simulationId,
        id: this.id,
        buildingId: this.buildingId,
        communeCode: this.communeCode,
        usageCode: this.usageCode,
        name: this.nameInternal,
        buildingNumber: this.buildingNumberInternal,
        sectorId: this.sectorIdInternal,
        constructionYear: this.constructionYearInternal,
        totalArea: parseInt(this.totalAreaInternal, 10),
      };
    },

    dataUpdatedDebounce() {
      clearTimeout(this.debounce);

      this.debounce = setTimeout(() => {
        this.$emit("data-update", this.getCurrentModel());
      }, this.debounceTimeoutMillis);
    },

    sectorUpdatedDebouce() {
      clearTimeout(this.sectorDebouce);

      this.sectorDebouce = setTimeout(() => {
        this.$emit("sector-update", {
          id: this.id,
          sectorId: this.sectorIdInternal,
        });
      }, this.debounceTimeoutMillis);
    },

    constructionYearUpdatedDebouce() {
      clearTimeout(this.constructionYearDebouce);

      this.constructionYearDebouce = setTimeout(() => {
        this.$emit("construction-year-update", {
          buildingId: this.buildingId,
          constructionYear: parseInt(this.constructionYearInternal, 10),
        });
      }, this.debounceTimeoutMillis);
    },

    remove() {
      this.$emit("data-remove", this.getCurrentModel());
    },

    duplicate() {
      this.$emit("data-duplicate", this.getCurrentModel());
    },

    validateTotalArea(s) {
      return s !== null && s.toString().length > 0 && !/\D/.test(s.toString());
    },

    validateConstructionYear(s) {
      return this.validateTotalArea(s);
    },

    validateName(s) {
      return s.length > 0;
    },

    tooltipClicked(tooltip) {
      this.$emit("tooltip-clicked", tooltip);
    },
  },
};
