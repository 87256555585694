import { render, staticRenderFns } from "./WidgetB.vue?vue&type=template&id=26db0376"
import script from "./WidgetB.vue?vue&type=script&lang=js"
export * from "./WidgetB.vue?vue&type=script&lang=js"
import style0 from "./WidgetB.vue?vue&type=style&index=0&id=26db0376&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WidgetKeyValue: require('/app/components/Widget/WidgetKeyValue.vue').default})
