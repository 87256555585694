
export default {
  inheritAttrs: false,

  props: {
    valueMin: {
      type: Number,
      default: 0
    },

    valueMax: {
      type: Number,
      default: 6
    },

    value: {
      type: Number,
      default: 0
    },

    tooltipTitle: {
      type: String,
      required: true
    }
  },

  methods: {
    getBarWidth() {
      return 100 / this.valueMax * this.value;
    },

    getBarColor() {
      switch (this.value) {
        case 1:
        case 2:
          return "--color-black-50";

        case 3:
        case 4:
          return "--color-black-50";

        case 5:
        case 6:
          return "--color-black-50";
      }
    }
  }
}
