// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".controls_wdvhD{align-items:center;display:flex}.control_PHv2M{cursor:pointer;display:flex;transition-duration:var(--transition-duration-shortest);transition-property:transform;transition-timing-function:ease-in-out}.control_PHv2M:hover{transform:scale(1.15)}.icon_75xZn{display:inline;margin-left:1.5rem}.simple-table-row td{transition-duration:var(--transition-duration-short);transition-property:background-color;transition-timing-function:ease-in-out}.simple-table-row.highlight td{background-color:var(--color-yellow-100)!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls": "controls_wdvhD",
	"control": "control_PHv2M",
	"icon": "icon_75xZn"
};
module.exports = ___CSS_LOADER_EXPORT___;
