// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bar_iSO2I,.container_LBChc,.label_zKFMG{display:flex;height:100%;position:absolute;width:100%}.container_LBChc{border-right:1.25rem solid transparent;height:calc(100% - 1rem);margin-top:.5rem}.label_zKFMG{align-items:center;background-color:transparent;background-color:initial;justify-content:center}.table-cell-bar-label>*{opacity:.4}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bar": "bar_iSO2I",
	"container": "container_LBChc",
	"label": "label_zKFMG"
};
module.exports = ___CSS_LOADER_EXPORT___;
