
export default {
  inheritAttrs: false,

  methods: {
    click() {
      this.$store.commit("sortableTable/setConfigureColumnsVisible", !this.$store.getters["sortableTable/getConfigureColumnsVisible"]);
    },
  },
};
