// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_i6jkc,.outerContainer_fLb8x{display:flex;flex-direction:column}.container_i6jkc{background-color:var(--color-black-400);border-radius:var(--border-radius-large);box-shadow:var(--box-shadow-large);overflow:hidden}.notification_dP6Fa{background-color:var(--color-yellow-50);border-bottom:1px solid var(--color-yellow-200);outline:solid 1px var(--color-yellow-100);outline-offset:-1px;padding:1rem 1rem 1rem .75rem}.closeButton_qq28k,.collapseToggle_uavw8,.filter_uku4k,.filterItem_h3jPt,.filterItemNested_Vzevl,.filterToggle_XUDF0,.filterToggleItem_LTkcp,.header_URlOa{display:flex}.header_URlOa{cursor:pointer;justify-content:space-between;padding:1rem}.filter_uku4k{background-color:rgba(0,0,0,.2);border-top:1px solid rgba(0,0,0,.4);position:relative}.closeButton_qq28k{position:absolute;right:0;top:.25rem}.filterItem_h3jPt{flex-wrap:wrap}.filterItemNested_Vzevl{margin-bottom:1rem}.filterItemNested_Vzevl:not(:last-child){margin-right:2.5rem}.filterToggle_XUDF0{flex-wrap:wrap}.filterToggleItem_LTkcp{border-top:1px solid rgba(0,0,0,.4);cursor:pointer;flex-grow:1;padding:.5rem 1rem;-webkit-user-select:none;-moz-user-select:none;user-select:none}.filterToggleItem_LTkcp.error_85\\+K4{outline:solid 1px var(--color-red-500);outline-offset:-1px}.filterToggleItem_LTkcp.active_ccAeG,.filterToggleItem_LTkcp:hover{background-color:rgba(0,0,0,.2)}.filterToggleItem_LTkcp{border-right:1px solid rgba(0,0,0,.4)}.collapseToggle_uavw8{margin-left:3rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_i6jkc",
	"outerContainer": "outerContainer_fLb8x",
	"notification": "notification_dP6Fa",
	"closeButton": "closeButton_qq28k",
	"collapseToggle": "collapseToggle_uavw8",
	"filter": "filter_uku4k",
	"filterItem": "filterItem_h3jPt",
	"filterItemNested": "filterItemNested_Vzevl",
	"filterToggle": "filterToggle_XUDF0",
	"filterToggleItem": "filterToggleItem_LTkcp",
	"header": "header_URlOa",
	"error": "error_85+K4",
	"active": "active_ccAeG"
};
module.exports = ___CSS_LOADER_EXPORT___;
