// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_0aCcj{display:flex;flex-direction:column;height:100%}.comparisonNewLink_ydEbD{align-items:center;display:flex;justify-content:center;padding:0 1.25rem}.comparisonNewLinkLoading_iehaT{cursor:wait;pointer-events:none}.sticky_Q8uDe{bottom:1rem;display:flex;flex-direction:row;margin:2rem auto 0;max-width:60%;position:sticky;z-index:1}.expandUnitTable_tmPX4{align-items:center;padding:.5rem 1rem 1rem}.actions_bJ7bV,.expandUnitTable_tmPX4{display:flex;justify-content:center}.actions_bJ7bV{align-items:flex-end;background-color:var(--color-white);flex-direction:column;height:100%;padding:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_0aCcj",
	"comparisonNewLink": "comparisonNewLink_ydEbD",
	"comparisonNewLinkLoading": "comparisonNewLinkLoading_iehaT",
	"sticky": "sticky_Q8uDe",
	"expandUnitTable": "expandUnitTable_tmPX4",
	"actions": "actions_bJ7bV"
};
module.exports = ___CSS_LOADER_EXPORT___;
