
export default {
  inheritAttrs: false,

  props: {
    simulationId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      key: "address",
      direction: "desc",
    };
  },

  computed: {
    unitLocal() {
      return this.$t("UNITS_M2");
    },
  },

  methods: {
    sort(key) {
      if (this.key === key) {
        this.direction = this.direction === "desc" ? "asc" : "desc";
      } else {
        this.key = key;
        this.direction = "desc";
      }

      this.$emit("data-sort", {
        comparisonId: this.comparisonId,
        key: this.key,
        direction: this.direction,
      });
    },
  },
};
