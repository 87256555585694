
import Overlay from "~/components/Chrome/components/Overlay/ChromeOverlay.vue";

export default {
  title() {
    return "lejedata-no-access";
  },

  components: { Overlay },

  methods: {
    detailsClicked() {
      this.$amplitude.lejetjekNoAccessDetails();
    },

    upgradeClicked() {
      this.$amplitude.lejetjekNoAccessUpgrade();
    },
  },
};
