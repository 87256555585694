// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containerLink_83eUM{padding:.75rem 0}.containerAlignment_Vm81t{display:flex;justify-items:flex-end}.icon_i4yav{transform:translateY(-2px)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerLink": "containerLink_83eUM",
	"containerAlignment": "containerAlignment_Vm81t",
	"icon": "icon_i4yav"
};
module.exports = ___CSS_LOADER_EXPORT___;
