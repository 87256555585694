
import { colorNameBySectorId } from "~/helpers/rent-level-helpers.js";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      r: /--[a-z]+-[a-z]+-/gi,
    };
  },

  methods: {
    getBackgroundColor(percentage, serie) {
      // Map to 8 shades of grey starting from 100
      const shade = Math.floor(percentage / 12.5) * 100 + 100;

      const color = colorNameBySectorId(serie.type);

      return `--color-${color}-${shade}`;
    },

    getForegroundColor(percentage, serie) {
      const shade = Math.floor(percentage / 12.5) * 100 + 100;

      const color = colorNameBySectorId(serie.type);

      if (shade < 400) {
        return `--color-${color}-900`;
      } else {
        return `--color-white`;
      }
    },
  },
};
