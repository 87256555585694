
import { colorBySectorId } from "~/helpers/rent-level-helpers.js";
import TableCellBar from "./TableCellBar.vue";

export default {
  components: { TableCellBar },

  inheritAttrs: false,

  props: {
    address: {
      type: String,
      required: true,
    },

    sector: {
      type: String,
      default: undefined,
    },

    sectorId: {
      type: String,
      default: undefined,
    },

    buildingNumber: {
      type: Number,
      default: undefined,
    },

    constructionYear: {
      type: String,
      default: undefined,
    },

    totalArea: {
      type: String,
      default: undefined,
    },

    rentalPriceMin: {
      type: Number,
      default: undefined,
    },

    rentalPriceAverage: {
      type: Number,
      default: undefined,
    },

    rentalPriceMax: {
      type: Number,
      default: undefined,
    },

    rentalPriceYearly: {
      type: Number,
      default: undefined,
    },

    calculationAccuracy: {
      type: Number,
      default: undefined,
    },

    calculationAccuracyTooltip: {
      type: String,
      default() {
        return this.$t("VALUE_NOT_REGISTERED");
      },
    },
  },

  methods: {
    getColorBySectorId(sectorId) {
      return colorBySectorId(sectorId, true);
    },

    getSectorTooltip(sector) {
      switch (sector) {
        case "RESIDENTIAL_FREE_RENT":
          return this.$t("RENTAL_SECTOR_RESIDENTIAL_TOOLTIP");

        case "SHOP":
          return this.$t("RENTAL_SECTOR_COMMERCIAL_TOOLTIP");

        case "OFFICE":
          return this.$t("RENTAL_SECTOR_OFFICE_TOOLTIP");

        case "INDUSTRY":
          return this.$t("RENTAL_SECTOR_INDUSTRY_TOOLTIP");

        case "OTHER_BUSINESS":
          return this.$t("RENTAL_SECTOR_OTHER_BUSINESS_TOOLTIP");

        case "RESIDENTIAL_COST":
          return this.$t("RENTAL_SECTOR_RESIDENTIAL_COST_TOOLTIP");

        case "RESIDENTIAL_SMALL":
          return this.$t("RENTAL_SECTOR_RESIDENTIAL_SMALL_HOUSE_RENTAL_TOOLTIP");

        case "RESIDENTIAL_RENTAL":
          return this.$t("RENTAL_SECTOR_RESIDENTIAL_RENTAL_VALUE_TOOLTIP");

        default:
          return this.$t("VALUE_NOT_REGISTERED");
      }
    },

    tooltipClicked(tooltip) {
      this.$emit("tooltip-clicked", tooltip);
    },
  },
};
