
import { getTypeFromSector } from "~/helpers/rent-level-helpers.js";

export default {
  inheritAttrs: false,

  props: {
    sectors: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    return {
      bulkEditModalSectorOldValue: "RESIDENTIAL_COST",
      bulkEditModalSectorNewValue: "RESIDENTIAL_RENTAL",
    };
  },

  methods: {
    getTypeFromSector: getTypeFromSector,
  },
};
