// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_6JtO5{display:flex;flex-direction:column;height:-moz-fit-content;height:fit-content;width:100%}.background_gBOr9{align-items:center;display:inline-flex;flex-grow:1;height:3rem;justify-content:center;width:100%}.legend_k7m9i,.legendItem_BqVsJ,.legendItemCaption_23Atw,.legendItemColor_B1jvL{align-items:center;display:flex;justify-content:center}.legend_k7m9i{margin-bottom:2rem}.legendItem_BqVsJ{align-items:center;height:2rem;justify-content:center;min-width:2rem}.legendItemCaption_23Atw{margin-right:1rem}.legendItemColor_B1jvL{height:100%;min-width:3.5rem}.test-heatmap .simple-table-row:not(:last-child){border-bottom:1px solid var(--color-white)}.test-heatmap .simple-table-cell:not(:last-child){border-right:1px solid var(--color-white)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_6JtO5",
	"background": "background_gBOr9",
	"legend": "legend_k7m9i",
	"legendItem": "legendItem_BqVsJ",
	"legendItemCaption": "legendItemCaption_23Atw",
	"legendItemColor": "legendItemColor_B1jvL"
};
module.exports = ___CSS_LOADER_EXPORT___;
