
import { getTypeFromSector } from "~/helpers/rent-level-helpers.js";
import { colorBySectorId } from "~/helpers/rent-level-helpers.js";

import SortableTablColumnToggleButton from "~/components/SortableTable/SortableTablColumnToggleButton.vue";

export default {
  components: {
    SortableTablColumnToggleButton,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    showHiddenTypesTooltip: {
      type: Boolean,
      default: false,
    },

    header: {
      type: String,
      default: "RENT_LEVELS_NEW_WIDGETA_HEADER",
    },

    omitTotals: {
      type: Boolean,
      default: false,
    },

    omitVacancyRate: {
      type: Boolean,
      default: false,
    },

    hideDownloadButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      userPreferences: undefined,
    };
  },

  computed: {
    dataAsArray() {
      if (!this.data) {
        return 0;
      }

      return Object.entries(this.data).map(([key, value]) => ({
        sector: key,
        type: getTypeFromSector(key, this.$i18n),
        ...value,
      }));
    },

    totalTopRent() {
      if (!this.data) {
        return 0;
      }

      return this.dataAsArray.reduce((acc, cur) => acc + cur.totalYearlyTopRent, 0);
    },

    totalAvgRent() {
      if (!this.data) {
        return 0;
      }

      return this.dataAsArray.reduce((acc, cur) => acc + cur.totalYearlyAvgRent, 0);
    },

    totalBottomRent() {
      if (!this.data) {
        return 0;
      }

      return this.dataAsArray.reduce((acc, cur) => acc + cur.totalYearlyBottomRent, 0);
    },

    columnsCount() {
      let columCount = [
        this.userPreferences?.type ?? false,
        this.userPreferences?.totalYearlyBottomSquareMeterRent ?? false,
        this.userPreferences?.totalYearlyAvgSquareMeterRent ?? false,
        this.userPreferences?.totalYearlyTopSquareMeterRent ?? false,
      ].filter((x) => x).length;

      if (!this.omitTotals) {
        columCount += [
          this.userPreferences?.type ?? false,
          this.userPreferences?.totalYearlyBottomSquareMeterRent ?? false,
          this.userPreferences?.totalYearlyAvgSquareMeterRent ?? false,
          this.userPreferences?.totalYearlyTopSquareMeterRent ?? false,
        ].filter((x) => x).length;
      }

      return columCount;
    },

    columnsWithTotalsCount() {
      if (this.omitTotals) {
        return 0;
      }

      return [
        this.userPreferences?.totalYearlyTopRent ?? false,
        this.userPreferences?.totalYearlyAvgRent ?? false,
        this.userPreferences?.totalYearlyBottomRent ?? false,
      ].filter((x) => x).length;
    },

    unitLocal() {
      return this.$t("UNITS_M2");
    },

    tableHeaders() {
      return [
        {
          labelText: this.$t("RENT_LEVELS_NEW_WIDGETA_TYPE"),
          dataProperty: "type",
          sortActive: true,
          sortOrderDesc: false,
          textAlignEnd: false,
        },
        ...(!this.omitTotals
          ? [
              {
                labelText: this.$t("RENT_LEVELS_NEW_WIDGETA_TOTAL_TOP_RENT"),
                dataProperty: "totalYearlyTopRent",
                textAlignEnd: true,
              },
              {
                labelText: this.$t("RENT_LEVELS_NEW_WIDGETA_TOTAL_AVG_RENT"),
                dataProperty: "totalYearlyAvgRent",
                textAlignEnd: true,
              },
              {
                labelText: this.$t("RENT_LEVELS_NEW_WIDGETA_TOTAL_BOTTOM_RENT"),
                dataProperty: "totalYearlyBottomRent",
                textAlignEnd: true,
              },
            ]
          : []),
        {
          labelText: this.$t("RENT_LEVELS_NEW_WIDGETA_TOTAL_BOTTOM_RENT_KVM", { area: this.unitLocal }),
          dataProperty: "totalYearlyBottomSquareMeterRent",
          textAlignEnd: true,
        },

        {
          labelText: this.$t("RENT_LEVELS_NEW_WIDGETA_TOTAL_AVG_RENT_KVM", { area: this.unitLocal }),
          dataProperty: "totalYearlyAvgSquareMeterRent",
          textAlignEnd: true,
        },

        {
          labelText: this.$t("RENT_LEVELS_NEW_WIDGETA_TOTAL_TOP_RENT_KVM", { area: this.unitLocal }),
          dataProperty: "totalYearlyTopSquareMeterRent",
          textAlignEnd: true,
        },
        ...(!this.omitVacancyRate
          ? [
              {
                labelText: this.$t("RENT_LEVELS_WIDGET_A_VACANCY_RATE"),
                dataProperty: "vacancyRate",
                textAlignEnd: true,
              },
            ]
          : []),
      ];
    },
  },

  methods: {
    colorBySectorId: colorBySectorId,

    userPreferencesChange(value) {
      this.userPreferences = value;
    },
  },
};
